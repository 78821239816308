import useApiCall from '@/hooks/useApiCall';
import { TTagCategory } from '@/pages/Part/types';
import { ReactNode, createContext, useEffect, useState } from 'react';

export const CategoryContext = createContext<{
  tagCategories: TTagCategory[];
  setTagCategories: React.Dispatch<React.SetStateAction<TTagCategory[]>>;
}>({
  tagCategories: [],
  setTagCategories: () => {},
});

type TTagCategoryProvider = {
  children: ReactNode;
};

export const CategoryProvider = ({ children }: TTagCategoryProvider) => {
  const { makeApiCall } = useApiCall<TTagCategory[]>();

  const [tagCategories, setTagCategories] = useState<TTagCategory[]>([]);

  useEffect(() => {
    const getCategories = async () => {
      const cats = await makeApiCall({
        useAsFetch: true,
        method: 'GET',
        url: import.meta.env.VITE_APP_API_URL + '/tag_categories',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setTagCategories(cats.tag_categories);
    };

    getCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        tagCategories,
        setTagCategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
