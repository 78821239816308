import { Layout } from '@/pages/Layout';
import { Login } from '@/pages/Login';
import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { LanguageProvider } from '@data-products-and-ai/react-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { CONSTANTS } from './constants/constants';
import { Frame } from './pages/Frame';
import { Part } from './pages/Part';
import { PartCompare } from './pages/PartCompare';

import { ErrorBoundary } from './contexts/ErrorBoundary';
import { About } from './pages/About';
import { Administration } from './pages/Administration';
import { Frames } from './pages/Frames';
import { Home } from './pages/Home';
import { PartPedigree } from './pages/PartPedigree';
import { Parts } from './pages/Parts';
import { CategoryProvider } from './contexts/TagCategories/TagCategories';

function App() {
  /*Make_reusable - page not found */
  const NotFoundPage = () => {
    return <>Not found</>;
  };

  const LoginToggle = () => {
    return (
      <>
        <LanguageProvider translations={CONSTANTS} defaultLanguage="en">
          <AuthenticatedTemplate>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
            >
              <ErrorBoundary>
                <CategoryProvider>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      <Route index element={<Home />} />

                      <Route path="administration">
                        <Route index element={<Administration />} />
                        <Route path=":status" element={<Administration />} />
                      </Route>

                      <Route path="about" element={<About />} />
                      <Route
                        path="data"
                        element={<Parts searchType="data_approval" />}
                      />
                      <Route path="parts">
                        <Route index element={<Parts />} />
                        <Route path="search/:search_id" element={<Parts />} />
                        <Route path="detail/:part_id" element={<Part />} />
                        <Route path="compare" element={<PartCompare />} />
                        <Route
                          path="compare/:part_id"
                          element={<PartCompare />}
                        />
                        <Route
                          path="part_development"
                          element={<PartPedigree />}
                        />
                        <Route
                          path="part_development/:part_id"
                          element={<PartPedigree />}
                        />
                      </Route>
                      <Route path="frames">
                        <Route index element={<Frames />} />
                        <Route path=":frame_family_id" element={<Frame />} />
                        <Route
                          path=":frame_family_id/:frame_id"
                          element={<Frame />}
                        />
                      </Route>

                      <Route path="data_approval">
                        <Route
                          index
                          element={<Parts searchType="data_approval" />}
                        />
                        <Route
                          path=":status"
                          element={<Parts searchType="data_approval" />}
                        />
                      </Route>
                    </Route>

                    <Route path="/" element={<Layout />}>
                      <Route path="*" element={<NotFoundPage />} />
                    </Route>
                  </Routes>
                </CategoryProvider>
              </ErrorBoundary>
            </MsalAuthenticationTemplate>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </LanguageProvider>
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/notfound" element={<NotFoundPage />} />
        <Route path="*" element={<LoginToggle />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
