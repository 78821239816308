import { Spinner } from '@/components/Spinner';
import { AreaContext } from '@/contexts/AreaContext';
import { TSearchParametersWithEndpointLabel } from '@/contexts/AreaContext/AreaContext';
import { searchParametersWithEndpointLabel } from '@/contexts/AreaContext/defaultObjects';
import useApiCall from '@/hooks/useApiCall';
import { TPartTag } from '@/pages/Part/types';
import { Label, SelectV2 } from '@data-products-and-ai/react-components';
import {
  Option,
  OptionGroup,
} from '@data-products-and-ai/react-components/lib/components/Forms/SelectV2/SelectV2';
import { useContext, useEffect } from 'react';
//https://stackoverflow.com/questions/46571811/react-select-auto-size-width/68551906 - check width
//https://github.com/JedWatson/react-select/issues/3880
//https://stackoverflow.com/questions/70897522/react-selects-short-input-box-component-width-causes-cursor-position-problem
type TSearchItem = {
  itemKey: keyof TSearchParametersWithEndpointLabel;
  multipleSelection?: boolean;
  personalClassification?: boolean;
  disabled?: boolean;
};

const SearchTags = (props: TSearchItem) => {
  const { searchParameters, setSearchParameters } = useContext(AreaContext);
  const { isLoading, error, data, makeApiCall } = useApiCall<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>();

  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url:
        import.meta.env.VITE_APP_API_URL +
        '/' +
        searchParametersWithEndpointLabel[props.itemKey].endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;
  if (error) return 'Error';

  if (data) {
    const groupedByCategory: Option[] = data.tags.reduce(
      (acc: OptionGroup[], item: TPartTag) => {
        const { category, tag, id } = item;

        // Check if the category already exists in the accumulator
        let categoryGroup = acc.find((cat) => cat.label === category);

        // If the category doesn't exist, create it and add to the accumulator
        if (!categoryGroup) {
          categoryGroup = { label: category, options: [] };
          acc.push(categoryGroup);
        }

        // Add the current item as an option to the appropriate category
        if (category !== '')
          categoryGroup.options.push({ label: tag, value: id.toString() });

        return acc;
      },
      [],
    );

    //
    return (
      <div>
        <Label>{searchParametersWithEndpointLabel[props.itemKey].label}</Label>
        <SelectV2
          multiLine
          noOptionsMessage="No tag found"
          placeholder="Tags"
          hideIndicator
          size="small"
          width="100%"
          isMulti
          menuWithCheckbox
          closeMenuOnSelect={false}
          options={groupedByCategory}
          selectedValues={data.tags
            .filter((tag: TPartTag) =>
              searchParameters[props.itemKey]
                .split(',')
                .includes(String(tag.id)),
            )
            .map((tag: TPartTag) => ({
              label: tag.tag,
              value: String(tag.id),
            }))}
          onChange={(value) => {
            if (Array.isArray(value)) {
              const commaSeparatedString = value
                .map((item) => item.value)
                .join(',');

              setSearchParameters((prevState) => ({
                ...prevState,
                [props.itemKey]: commaSeparatedString,
              }));
            }
            /* setSearchParameters((prevState) => ({
              ...prevState,
              [props.itemKey]: commaSeparatedString,
            })); */
          }}
        />
      </div>
    );
  }

  return null;
};

export default SearchTags;
