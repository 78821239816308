import {
  TOnlySearchItems,
  TPersonalParameters,
  TSearchParameters,
} from './AreaContext';

export const searchParametersWithEndpointLabel: TOnlySearchItems = {
  part_type: {
    endpoint: 'part_types',
    label: 'Part Type',
    has_id: false,
  },
  frame_family: {
    endpoint: 'frame_families',
    label: 'Frame Family',
    has_id: false,
  },
  application: {
    endpoint: 'applications',
    label: 'Application',
    has_id: false,
  },
  pdp_status: {
    endpoint: 'pdp_statuses',
    label: 'PDP Status',
    has_id: true,
  },
  sap_status: {
    endpoint: 'sap_statuses',
    label: 'SAP Status',
    has_id: false,
  },
  engineering_status: {
    endpoint: 'engineering_statuses',
    label: 'Engineering Status',
    has_id: true,
  },
  base_material: {
    endpoint: 'base_materials',
    label: 'Base Material',
    has_id: false,
  },
  internal_coating: {
    endpoint: 'internal_coatings',
    label: 'Internal Coating',
    has_id: false,
  },
  external_coating: {
    endpoint: 'external_coatings',
    label: 'External Coating',
    has_id: false,
  },
  thermal_barrier: {
    endpoint: 'thermal_barriers',
    label: 'Thermal Barrier',
    has_id: false,
  },
  top_coat: {
    endpoint: 'top_coats',
    label: 'Top Coat',
    has_id: false,
  },
  earmark: {
    endpoint: 'earmarks',
    label: 'Earmark',
    has_id: true,
  },
  design_feature: {
    endpoint: 'design_features',
    label: 'Design Feature',
    has_id: true,
  },
  casting_vendor: {
    endpoint: 'casting_vendors',
    label: 'Casting Vendor',
    has_id: false,
  },
  source: { endpoint: 'sources', label: 'Source', has_id: false },
  assembly_cluster: {
    endpoint: 'assembly_clusters',
    label: 'Assembly Cluster',
    has_id: false,
  },
  life_cluster: {
    endpoint: 'life_clusters',
    label: 'Life Cluster',
    has_id: false,
  },
  part_owners: {
    endpoint: 'part_owners',
    label: 'Owner(s)',
    has_id: false,
  },
  tags: {
    endpoint: 'tags',
    label: 'Tags',
    has_id: true,
  },
} as const;

export const initialSearchParameters: TSearchParameters = {
  bom_list: '',
  text: '',
  bom: '',
  part_type: '',
  frame_family: '',
  application: '',
  pdp_status: '',
  sap_status: '',
  engineering_status: '',
  base_material: '',
  internal_coating: '',
  external_coating: '',
  thermal_barrier: '',
  top_coat: '',
  earmark: '',
  design_feature: '',
  casting_vendor: '',
  source: '',
  life_cluster: '',
  assembly_cluster: '',
  part_owners: '',
  tags: '',
};

export const initialPersonalParameters: TPersonalParameters = {
  bom_list: [],
  text: [],
  bom: [],
  part_type: [],
  frame_family: [],
  application: [],
  pdp_status: [],
  sap_status: [],
  engineering_status: [],
  base_material: [],
  internal_coating: [],
  external_coating: [],
  thermal_barrier: [],
  top_coat: [],
  earmark: [],
  design_feature: [],
  casting_vendor: [],
  source: [],
  life_cluster: [],
  assembly_cluster: [],
  part_owners: [],
  tags: [],
};
